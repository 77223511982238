.cell_title {
    width: 500px;
    border: 1px solid #ccc;
    margin: 0px;
    padding: 5px;
    background-color: #f5f5f5;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
}
.cell_medium {
    width: 200px;
    border: 1px solid #ccc;
    margin: 0px;
    padding: 5px;
    background-color: #f5f5f5;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
}
.cell {
    width: 100px;
    border: 1px solid #ccc;
    margin: 0px;
    padding: 5px;
    background-color: #f5f5f5;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
}

/* Contenedor para alinear en fila */
.contenedor {
    display: flex;
    flex-wrap: wrap;
}

/* Primera fila */
.contenedor_first:first-child .cell {
    background-color: #8315dd; /* Azul */
    color: white;
}

/* Primera columna de cada fila */
.contenedor .cell:first-child {
    background-color: #954ecf; /* Verde */
    color: white;
}


.casillas {
     color: red;
     margin-left: 10px;
}


.container {
    padding: 20px;
    background: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .title {
    font-size: 18px;
    font-weight: bold;
    color: #333;
    margin-bottom: 15px;
  }
  
  .grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 15px;
  }
  
  .discountCard {
    background: white;
    border-radius: 8px;
    padding: 15px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease-in-out;
  }
  
  .discountCard:hover {
    transform: translateY(-3px);
  }
  
  .discountTitle {
    font-size: 16px;
    font-weight: 600;
    color: #007bff;
    margin-bottom: 10px;
  }
  
  .labels {
    font-size: 14px;
    color: #444;
    margin-bottom: 8px;
  }
  
  .total {
    font-size: 14px;
    font-weight: bold;
    color: #d9534f;
    margin-top: 10px;
  }